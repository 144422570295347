import React, { useCallback, useEffect, useState } from "react";
import "./UsersList.scss";
import view from "../../../assets/images/teams/icons/view.png";
import hide from "../../../assets/images/teams/icons/hide.png";
import subscription from "../../../assets/images/icons/subscription.jpg";
import { Space, Table, Tooltip, Input, Modal } from "antd";
import AdminLayout from "../../../components/AdminComponents/AdminLayout/AdminLayout";
import { GetAllUSersList } from "../../../utils/apiCalls";
import Subscriptions from "../Subscriptions/Subscriptions";
import { useTranslation } from "react-i18next";
import Confirm from "../../../components/confirmModal/confirm";
const { Search } = Input;

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

const UsersList = () => {
  const { t } = useTranslation();
  const userToken = localStorage.getItem("token");
  const [usersList, setUsersList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [userPortal, setUserPortal] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [search, setSearch] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [addedAddonUser, setAddedAddonUser] = useState("");
  const [addedAddon, setAddedAddon] = useState("");
  const baseUrl = window.location.origin;

  const debounceSearch = useCallback(
    debounce((value) => {
      setPageIndex(1);
      setPageSize(10);
      setSearch(value);
    }, 1000),
    []
  );

  useEffect(() => {
    debounceSearch(searchString);
  }, [searchString, debounceSearch]);

  const handleUser = (record) => {
    const token = record?.token;
    localStorage.setItem("token", token);
    setUserPortal(true);
  };

  const handleBackToAdmin = () => {
    localStorage.setItem("token", null);
    setUserPortal(false);
  };

  const getAllUsersData = useCallback(() => {
    const payload = {
      pageIndex: pageIndex - 1,
      pageSize: pageSize,
      searchString: search,
    };
    setLoading(true);
    GetAllUSersList((res) => {
      setLoading(false);
      setUsersList(res?.data);
      setTotalRecords(res?.totalRecords);
    }, payload);
  }, [pageIndex, pageSize, search]);

  useEffect(() => {
    getAllUsersData();
  }, [getAllUsersData]);

  const handleTableChange = (pagination) => {
    setPageIndex(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const handleSearch = (value) => {
    setSearchString(value);
  };

  const tableStyles = {
    headerStyle: {
      backgroundColor: "#F8F8FA",
      color: "#ffffff",
    },
  };

  const columns = [
    {
      title: "User",
      dataIndex: "avatarUrl",
      key: "avatarUrl",
      render: (url) => <img src={url} alt="user-avatar" />,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {record?.token ? (
            <Tooltip title="View the user Account">
              <img
                onClick={() => handleUser(record)}
                className="view"
                src={view}
                alt="view-icon"
              />
            </Tooltip>
          ) : (
            <Tooltip title="User Is not Logged in">
              <img className="hide" src={hide} alt="hide-icon" />
            </Tooltip>
          )}
          <Tooltip title="Provide Addons">
            <img
              onClick={() => handleAddon(record)}
              className="hide"
              src={subscription}
              alt="hide-icon"
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addonRecord, setAddonRecord] = useState(null);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setAddonRecord(null);
    setAddedAddonUser("");
    setAddedAddon("");
  };

  const handleAddon = (record) => {
    setAddonRecord(record);
    setIsModalOpen(true);
  };

  const successMessage = `${t("success_content1")} ${addedAddon} ${t(
    "success_content1.1"
  )} ${addedAddonUser?.name ?? addedAddonUser}`;

  return (
    <AdminLayout>
      {userPortal ? (
        userToken === null ||
        userToken === "null" ||
        userToken === undefined ? (
          <div className="users-list">
            <div className="users-list__container">
              <div className="users-list__section">
                <div className="users-list__section__data">
                  <div className="users-list__section__data__search">
                    <Search
                      placeholder="Search users"
                      onSearch={handleSearch}
                      onChange={(e) => handleSearch(e.target.value)}
                      style={{ width: 300 }}
                    />
                  </div>
                  <Table
                    columns={columns}
                    dataSource={usersList}
                    loading={loading}
                    pagination={{
                      current: pageIndex,
                      pageSize: pageSize,
                      total: totalRecords,
                      showSizeChanger: true,
                    }}
                    onChange={handleTableChange}
                    rowKey="id"
                    bordered
                    title={() => <h2>User List</h2>}
                    style={tableStyles.headerStyle}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="users-list__userportal">
            <div className="users-list__userportal__heading">
              <button onClick={handleBackToAdmin}>Back To Users List</button>
            </div>
            <div className="users-list__userportal__section">
              <iframe
                src={`${baseUrl}/projects`}
                title="Embedded Page"
                width="100%"
                height="760px"
              ></iframe>
            </div>
          </div>
        )
      ) : (
        <div className="users-list">
          <div className="users-list__container">
            <div className="users-list__section">
              <div className="users-list__section__data">
                <div className="users-list__section__data__search">
                  <Search
                    placeholder="Search users"
                    onSearch={handleSearch}
                    onChange={(e) => handleSearch(e.target.value)}
                    style={{ width: 300 }}
                  />
                </div>
                <Table
                  columns={columns}
                  dataSource={usersList}
                  loading={loading}
                  pagination={{
                    current: pageIndex,
                    pageSize: pageSize,
                    total: totalRecords,
                    showSizeChanger: true,
                  }}
                  onChange={handleTableChange}
                  rowKey="id"
                  bordered
                  title={() => <h2>User List</h2>}
                  style={tableStyles.headerStyle}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        title="Addons"
        centered
        open={isModalOpen}
        onCancel={handleCloseModal}
        footer={null}
        width={1000}
      >
        <Subscriptions
          addonRecord={addonRecord}
          onClose={handleCloseModal}
          setIsSuccess={setIsSuccess}
          setAddedAddon={setAddedAddon}
          setAddedAddonUser={setAddedAddonUser}
        />
      </Modal>
      {isSuccess && (
        <Confirm
          buttonText={t("ok")}
          isCancelRequired={false}
          confirmTitle={t("congratulations")}
          content={successMessage}
          onConfirm={() => setIsSuccess(false)}
        />
      )}
    </AdminLayout>
  );
};

export default UsersList;
