/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./RemoveSchedulePlan.scss";
import cross from "../../../assets/images/workspace/icons/cross-icon.png";
import gif from "../../../assets/images/workspace/upscale-gif.gif";
import { useTranslation } from "react-i18next";
import { GetUserMembershipUpgrade } from "../../../utils/apiCalls";

const RemoveSchedulePlan = ({
  handleCloseUpgradePopup,
  handleBuySubscription,
  isMemberShipData,
  isPlanDuration,
  handleCancelPlan,
}) => {
  const { t } = useTranslation();
  const [isMembershipPlanDetails, setIsMembershipPlanDetails] = useState();

  useEffect(() => {
    getSubscriptionSwitch();
  }, []);

  const getSubscriptionSwitch = () => {
    const lang = localStorage.getItem("language");
    const chinese = lang === "ch" ? true : false;
    const teamId = 0;
    GetUserMembershipUpgrade(
      (res) => {
        if (res?.status === 200) {
          setIsMembershipPlanDetails(res?.data);
        }
      },
      isMemberShipData?.id,
      chinese,
      teamId
    );
  };

  const handleCancel = () => {
    handleCancelPlan();
  };

  const handleSetSubscription = () => {
    handleBuySubscription();
    handleCloseUpgradePopup(isMemberShipData);
  };

  const originalDate = new Date(isMembershipPlanDetails?.dueDate);
  const year = originalDate.getFullYear();
  const month = (originalDate.getMonth() + 1).toString().padStart(2, "0");
  const day = originalDate.getDate().toString().padStart(2, "0");

  const formattedDate = `${year}/${month}/${day}`;

  return (
    <div
      className="remove-popup"
      onClick={() => handleCloseUpgradePopup(isMemberShipData)}
    >
      <div className="remove-popup__popup">
        <div
          className="remove-popup__popup__container"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="remove-popup__popup__section">
            <div className="remove-popup__popup__section__header">
              <h3>{t("remove_schedule")}</h3>
              <img
                className="cross"
                src={cross}
                alt="cross-icon"
                onClick={() => handleCloseUpgradePopup(isMemberShipData)}
              />
            </div>
            <div className="remove-popup__popup__section__content">
              <p>{t("remove_schedule_content1")}</p>
              <p>{t("remove_schedule_content2")}</p>
            </div>
            {isMembershipPlanDetails !== undefined ? (
              <>
                <div className="remove-popup__popup__section__plans">
                  <div className="remove-popup__popup__section__plan">
                    <h4>
                      {t("from")}&nbsp;
                      <span
                        style={{
                          fontWeight: "600",
                          color: isMembershipPlanDetails?.fromColor,
                        }}
                      >
                        {isMembershipPlanDetails?.fromPlan}
                        {isMembershipPlanDetails?.fromPlan !== "Free Plan" &&
                          isMembershipPlanDetails?.fromCategory}
                      </span>
                    </h4>
                    <div className="remove-popup__popup__section__plan__details">
                      {isMemberShipData?.upgradeOrDowngrade === "Downgrade" ||
                      isMemberShipData?.upgradeOrDowngrade === "降级计划" ? (
                        <h6>{t("Base_price")}</h6>
                      ) : (
                        <h6>{t("Base_price")}</h6>
                      )}
                      <h5>
                        ￥{isMembershipPlanDetails?.fromBasePrice}/
                        {isMembershipPlanDetails?.fromCategory === "Monthly"
                          ? `${t("month")}`
                          : `${t("year")}`}
                      </h5>
                    </div>
                  </div>
                  <div className="remove-popup__popup__section__plan">
                    <h4>
                      {t("to")}&nbsp;
                      <span
                        style={{
                          fontWeight: "600",
                          color: isMembershipPlanDetails?.toColor,
                        }}
                      >
                        {isMembershipPlanDetails?.toPlan}
                        {isMembershipPlanDetails?.toPlan !== "Free Plan" &&
                          isMembershipPlanDetails?.toCategory}
                      </span>
                    </h4>
                    <div className="remove-popup__popup__section__plan__details">
                      <h6>{t("Base_price")}</h6>
                      {isMembershipPlanDetails?.toPlan === "Free Plan" ? (
                        <h5>
                          ￥
                          {isMembershipPlanDetails?.toBasePrice === null
                            ? 0
                            : isMembershipPlanDetails?.toBasePrice}
                          /
                          {isMembershipPlanDetails?.toCategory === "Monthly"
                            ? `${t("month")}`
                            : `${t("year")}`}
                        </h5>
                      ) : (
                        <h5>
                          ￥
                          {isMembershipPlanDetails?.toPlanPrice === null
                            ? 0
                            : isMembershipPlanDetails?.toPlanPrice}
                          /
                          {isMembershipPlanDetails?.toCategory === "Monthly"
                            ? `${t("month")}`
                            : `${t("year")}`}
                        </h5>
                      )}
                    </div>
                  </div>
                </div>
                <div className="remove-popup__popup__section__buttons">
                  <div className="remove-popup__popup__section__buttons__section">
                    <button
                      onClick={() => handleCloseUpgradePopup(isMemberShipData)}
                      className="cancel"
                    >
                      {t("back")}
                    </button>
                    <button onClick={handleCancel}>
                      {t("confirm_change")}
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <div className="gif-icon">
                <img className="gif" src={gif} alt="gif" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemoveSchedulePlan;
