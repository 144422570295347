import React, { useContext, useEffect, useState } from "react";
import "./AddNewMember.scss";
import search from "../../../assets/images/teams/icons/search.png";
import image1 from "../../../assets/images/teams/icons/profile1.png";
import loaderimg from "../../../assets/images/workspace/upscale-gif.gif";
import { useTranslation } from "react-i18next";
import Flag from "react-flagkit";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  GetPhoneLengthCountry,
  InviteExistingUser,
  SearchExistingUser,
  SendInviteNewUser,
} from "../../../utils/apiCalls";
import { UserSocketContext } from "../../../store/UserSocketData";
import Button from "../../button";
import Confirm from "../../confirmModal/confirm";
import { useNavigate } from "react-router-dom";
import countryCodes from "../../../locales/countrycode.json";

const AddNewMember = ({
  team,
  onClose,
  teamCreated,
  setTeamCreated,
  handleExistingMember,
  privilegeSummary,
  getPrivilegeSummary,
  setIsInvited,
  isInvited,
  setShowInviteTeamMembers,
  setIsInviteLimitPopup,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { updateSocketData } = useContext(UserSocketContext);
  const [searchName, setSearchName] = useState("");
  const [phone, setPhone] = useState(null);
  const [countryCode, setCountryCode] = useState("86");
  const [countryName, setCountryName] = useState("China");
  const [phoneMinLength, setPhoneMinLength] = useState(null);
  const [phoneMaxLength, setPhoneMaxLength] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isValidate, setIsValidate] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [inviteSucess, setInviteSucess] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [isLoader1, setIsLoader1] = useState(false);

  const getFlag = () => {
    return <Flag />;
    return null;
  };

  const handleCountryChange = (country, countryCode) => {
    setCountryCode(country);
    setCountryName(countryCode.name);
    setPhone(null);
    setPhoneMaxLength(11);
    setErrorMessage("");
  };

  useEffect(() => {
    getPhoneLengthWithCountry();
  }, [countryName]);

  const getPhoneLengthWithCountry = () => {
    const payload = {
      countryName: countryName,
    };
    if (countryName !== "") {
      if (payload) {
        GetPhoneLengthCountry((res) => {
          if (res?.status === 200) {
            setPhoneMinLength(res?.data?.minLength);
            setPhoneMaxLength(res?.data?.maxLength);
          }
        }, payload);
      }
    }
  };

  const handleMobileChange = (event) => {
    const value = event;
    setPhone(value);
    const minLengthNumber = phoneMinLength;
    if (value) {
      if (
        value?.match(
          new RegExp(`^([0|\\+[0-9]{1,9})?([0-9]{${minLengthNumber}})$`)
        )
      ) {
        setIsValidate(true);
      } else {
        setErrorMessage(t("please_enter_a_valid_phone_number"));
        setIsValidate(false);
      }
    } else {
      setErrorMessage("");
    }
  };

  useEffect(() => {
    const delay = 1000;
    let timeoutId;

    const debounceSearch = () => {
      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        if (searchName !== "") {
          searchExistingUser();
        }
      }, delay);
    };

    debounceSearch();

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchName]);

  const searchExistingUser = () => {
    const teamId = team?.teamId;
    setIsLoader(true);
    setSearchData([]);
    SearchExistingUser(
      (res) => {
        if (res?.status === 200) {
          setSearchData(res?.data);
          setIsLoader(false);
        } else {
          setSearchData([]);
          setIsLoader(false);
        }
      },
      searchName,
      teamId
    );
  };

  const handleInviteName = (id) => {
    setIsLoader1(true);
    const payload = {
      teamId: team?.teamId,
      userId: [id],
    };
    InviteExistingUser((res) => {
      if (res?.status === 200) {
        // handleInviteSuccess();
        // getAllUserTeams();
        setIsInvited(!isInvited);
        getPrivilegeSummary();
        setSearchData([]);
        setIsLoader1(false);
        setInviteSucess(true);
        setSearchName("");
      } else if (res?.status === 203) {
        onClose();
        setIsInviteLimitPopup(true);
        setIsLoader1(false);
      } else if (res?.message === "Invalid access token") {
        updateSocketData({
          isExitPopup: true,
        });
      } else {
        setIsLoader1(false);
        setSearchName("");
      }
    }, payload);
  };

  const closeInviteNew = () => {
    setPhone("");
    setErrorMessage("");
    setShowInviteTeamMembers(false);
  };

  const handleInvite = () => {
    setIsLoader1(true);
    const payload = {
      teamId: team?.teamId,
      phoneNumber: phone,
      countryCode: `+${countryCode}`,
      countryName: countryName,
    };
    SendInviteNewUser((res) => {
      if (res?.status === 200) {
        // handleInviteSuccess();
        setIsInvited(!isInvited);
        getPrivilegeSummary();
        setPhone("");
        setIsLoader1(false);
        setInviteSucess(true);
      } else if (res?.status === 205) {
        setErrorMessage(t("invited_user_already_exists"));
        setIsValidate(false);
        setIsLoader1(false);
      } else if (res?.status === 203) {
        onClose();
        setIsInviteLimitPopup(true);
        setIsValidate(false);
        setIsLoader1(false);
      } else {
        setErrorMessage(res?.message);
        setIsValidate(false);
        setIsLoader1(false);
      }
    }, payload);
  };

  const handleClose = () => {
    setTeamCreated(false);
    onClose();
  };

  const handleBack = () => {
    onClose();
    handleExistingMember();
  };

  const handleContact = () => {
    navigate("/contact");
  };

  return (
    <div className="new-members">
      <div
        className="new-members__container"
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className={`new-members__section ${
            privilegeSummary?.userMemberLeftCount === 0 ? "no-data" : ""
          }`}
        >
          <div className="new-members__heading">
            <h2>{t("invite_members")}</h2>
            {privilegeSummary?.userMemberLeftCount === 0 ? (
              <p style={{ color: "red" }}>{t("member_limit_text")}</p>
            ) : (
              <p>
                {t("remaining_invitations")}{" "}
                {privilegeSummary?.userMemberLeftCount}
              </p>
            )}
          </div>
          {privilegeSummary?.userMemberLeftCount !== 0 ? (
            <div className="new-members__section__invite">
              <div className="new-members__section__invite__name">
                <h4>{t("add_member_existing")}</h4>
                <div className="new-members__section__invite__name__search">
                  <input
                    placeholder={t("add_member_existing_search")}
                    value={searchName}
                    onChange={(e) => setSearchName(e.target.value)}
                  />
                  <img src={search} alt="search-icon" />
                </div>
                <div className="new-members__section__invite__name__list">
                  {searchData?.length !== 0 ? (
                    searchData?.map((user, index) => {
                      return (
                        <div
                          className="new-members__section__invite__name__list__sec"
                          key={index}
                        >
                          <div className="new-members__section__invite__name__list__sec__left">
                            <img
                              src={user.avatarUrl ?? image1}
                              alt="user-logo"
                            />
                            <div className="new-members__section__invite__name__list__sec__left__sec">
                              <h4 className="data-max-length-Username">{user.username}</h4>
                              <h5>
                                {user.countryCode} {user.phoneNumber}
                              </h5>
                            </div>
                          </div>
                          <div className="new-members__section__invite__name__list__sec__right">
                            {user?.roleName === "Invite" ? (
                              <button
                                onClick={() => handleInviteName(user?.userId)}
                              >
                                <span>{t("invite")}</span>
                              </button>
                            ) : (
                              <span className="member">
                                {t(`team_${user?.roleName}`)}
                              </span>
                            )}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="new-members__section__invite__name__list__nodata">
                      {isLoader ? (
                        <img src={loaderimg} alt="loader-gif" />
                      ) : (
                        <p>{t("no_search_results")}</p>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="new-members__section__invite__number">
                <div className="new-members__section__invite__number__search">
                  <h5>{t("add_member_new_user")}</h5>
                  <div className="new-members__section__invite__number__search__input">
                    <div className="new-members__section__invite__number__search__input__inputs">
                      <PhoneInput
                        country={countryCodes.defaultCountry}
                        onlyCountries={countryCodes.Countries}
                        placeholder={t("country_code")}
                        value={countryCode}
                        maxLength={4}
                        onChange={(country, countryCode) =>
                          handleCountryChange(country, countryCode)
                        }
                        countryComponent={getFlag()}
                        readOnly={true}
                      />
                      <input
                        className="fill-num"
                        placeholder={`${t("add_member_new_user_search")}`}
                        value={phone === null ? "" : phone}
                        maxLength={phoneMaxLength}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                        onChange={(e) => handleMobileChange(e.target.value)}
                      />
                    </div>
                    {isValidate === false && errorMessage !== "" && (
                      <div className="error-p" style={{ color: "red" }}>
                        <p>{errorMessage}</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="new-members__section__invite__number__buttons">
                  <div className="new-members__section__invite__number__buttons__main">
                    <Button
                      buttonClick={closeInviteNew}
                      buttonClass="button_link"
                      buttonText={
                        teamCreated ? t("operate_next_time") : t("exit")
                      }
                    />
                    <Button
                      buttonClick={handleInvite}
                      buttonClass="button"
                      isBtnDisabled={
                        phone?.length !== 0 && isValidate
                          ? isLoader1
                            ? true
                            : false
                          : true
                      }
                      buttonText={t("invite")}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="new-members__section__buttons">
              <Button
                buttonClick={handleContact}
                buttonClass="button"
                isBtnDisabled={false}
                buttonText={t("contact_airi")}
              />
            </div>
          )}
        </div>
        {inviteSucess && (
          <Confirm
            buttonText={t("ok")}
            isCancelRequired={false}
            confirmTitle={t("team_invite_success")}
            content={t("team_invite_success_text")}
            onConfirm={() => setInviteSucess(false)}
            onCancel={() => setInviteSucess(false)}
          />
        )}
      </div>
    </div>
  );
};

export default AddNewMember;
