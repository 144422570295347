import React from "react";
import "./TooltipTop.scss";

const TootipTop = ({ TooltipContent, display }) => {
  const contentLines = TooltipContent?.split("/n");
  return (
    <div className="tooltipt">
      <div className="tooltipt__section">
        <div className="tooltipt__arrow"></div>
      </div>
      <div className="tooltipt__container">
        {contentLines?.map((item, index) => (
          <p key={index}>{item?.trim()}</p>
        ))}
      </div>
    </div>
  );
};

export default TootipTop;
