import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import i18n from "./App/i18n";
import { AuthDataProvider } from "./App/store/UserAuthData";
import { UserProvider } from "./App/store/UserDetailsStore";
import { ToolsetDataProvider } from "./App/store/WorkSpaceToolsetData";
import { DesignLibraryProvider } from "./App/store/DesignLibraryData";
import { RenderToolsetProvider } from "./App/store/RenderToolsetData";
import { EditToolsetProvider } from "./App/store/EditToolsetData";
import { UpscaleToolsetProvider } from "./App/store/UpscaleToolsetData";
import { CurrentProjectProvider } from "./App/store/CurrentProjectData";
import { ResponsiveDataProvider } from "./App/store/ResponsiveData";
import { ClientDataProvider } from "./App/store/ClientData";
import { UserSocketProvider } from "./App/store/UserSocketData";
import { TeamDesignProvider } from "./App/store/TeamDesignData";
import { AtmosphereRefProvider } from "./App/store/AtmosphereRefData";
import { AdminProvider } from "./App/store/AdminDetailsStore";
import { VideoToolsetProvider } from "./App/store/VideoToolsetData";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <AdminProvider>
        <UserProvider>
          <UserSocketProvider>
            <ClientDataProvider>
              <TeamDesignProvider>
                <ResponsiveDataProvider>
                  <AuthDataProvider>
                    <VideoToolsetProvider>
                      <AtmosphereRefProvider>
                        <DesignLibraryProvider>
                          <ToolsetDataProvider>
                            <RenderToolsetProvider>
                              <EditToolsetProvider>
                                <UpscaleToolsetProvider>
                                  <CurrentProjectProvider>
                                    <App />
                                  </CurrentProjectProvider>
                                </UpscaleToolsetProvider>
                              </EditToolsetProvider>
                            </RenderToolsetProvider>
                          </ToolsetDataProvider>
                        </DesignLibraryProvider>
                      </AtmosphereRefProvider>
                    </VideoToolsetProvider>
                  </AuthDataProvider>
                </ResponsiveDataProvider>
              </TeamDesignProvider>
            </ClientDataProvider>
          </UserSocketProvider>
        </UserProvider>
      </AdminProvider>
    </I18nextProvider>
  </React.StrictMode>
);

reportWebVitals();
